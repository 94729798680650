@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Synt Variable';
  src: url('../public/fonts/ABCSyntVariableVF-Trial.woff2') format('woff2'),
       url('../public/fonts/ABCSyntVariableVF-Trial.woff') format('woff'),
       url('../public/fonts/ABCSyntVariableVF-Trial.ttf') format('truetype-variations');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Synt';
  src: url('../public/fonts/ABCSynt-Regular-Trial.woff2') format('woff2'),
       url('../public/fonts/ABCSynt-Regular-Trial.woff') format('woff'),
       url('../public/fonts/ABCSynt-Regular-Trial.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Whyte';
  src: url('../public/fonts/ABCWhyteMono-Regular-Trial.woff2') format('woff2'),
       url('../public/fonts/ABCWhyteMono-Regular-Trial.woff') format('woff'),
       url('../public/fonts/ABCWhyteMono-Regular-Trial.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

/* Override dark mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
    color: black;
  }
}

body {
  background-color: white;
  font-family: 'Whyte', sans-serif;
}

.swiper-button-prev:after,
.swiper-button-next:after { 
  font-family: 'Synt' !important;
  color: #000 !important;
  font-size: 72px !important;
}

.swiper-button-prev:after {
  content: '\2190' !important;
}

.swiper-button-next:after {
  content: '\2192' !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute !important;
  top: 53% !important;
  transform: translateY(-50%) !important;
  z-index: 10 !important;
  cursor: pointer !important;
}

.schedule_zoomed-in {
  background-color: rgba(0, 0, 0, 0.8) !important;
  position: fixed;
  top: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 1000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.schedule_zoomed-in .swiper-button-next,
.schedule_zoomed-in .swiper-button-prev {
  display: none;
}

.swiper-button-prev {
  /* left: -60px !important;  */
}

.swiper-button-next {
  /* right: -60px !important;  */
}

a {
  @apply hover:underline;
}

/* LOGO SVG */

.cls-1_pointscrosses {
  clip-path: url(#clippath);
}

.cls-2_pointscrosses, .cls-3_pointscrosses, .cls-4_pointscrosses {
  stroke-width: 0px;
}

.cls-2_pointscrosses, .cls-5_pointscrosses {
  fill: none;
}

.cls-6_pointscrosses {
  clip-path: url(#clippath-1);
}

.cls-7_pointscrosses {
  clip-path: url(#clippath-3);
}

.cls-8_pointscrosses {
  clip-path: url(#clippath-2);
}

.cls-4_pointscrosses {
  fill: #231f20;
}

.cls-5_pointscrosses {
  stroke: #000;
  stroke-linecap: round;
}

.cls-1-wavesup {
  fill: #fff;
  stroke: #231f20;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-1_wavesdown {
  fill: #fff;
  stroke: #231f20;
  stroke-linecap: round;
  stroke-linejoin: round;
}          